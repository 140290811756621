import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CreateUserType, GenericPopup, UpdateUserAppsType } from '../pages/UserManagement/userManagementUtils/types';
import { useTranslation } from 'react-i18next';
import { ProfileResponseData } from '../pages/UserManagement/userManagementUtils/types';
import Popup from '../components/Popup/Popup';
import GenericMessage from '../components/GenericMessage/GenericMessage';

// Initial state for creating a user
export const INITIAL_CREATE_USER_BODY: CreateUserType = {
  accountId: 0,
  email: '',
  firstName: '',
  lastName: '',
  superUserStatus: false,
  applications: [],
};

// Create the context
const UserManagementContext = createContext<{
  createUserBody: CreateUserType;
  modifiedUserData: ProfileResponseData | null;
  modifiedUserApps: UpdateUserAppsType | null;
  genericPopup: GenericPopup | null;
  setCreateUserBody: React.Dispatch<React.SetStateAction<CreateUserType>>;
  setModifiedUserData: React.Dispatch<React.SetStateAction<ProfileResponseData | null>>;
  setModifiedUserApps: React.Dispatch<React.SetStateAction<UpdateUserAppsType | null>>;
  setGenericPopup: React.Dispatch<React.SetStateAction<GenericPopup | null>>;
} | null>(null);

type CreateUserProviderProps = {
  children: ReactNode;
};

// Create a provider component
export function UserManagementProvider({ children }: CreateUserProviderProps) {
  const { t } = useTranslation();
  const [createUserBody, setCreateUserBody] = useState<CreateUserType>(INITIAL_CREATE_USER_BODY); // User creation
  const [modifiedUserData, setModifiedUserData] = useState<ProfileResponseData | null>(null);
  const [modifiedUserApps, setModifiedUserApps] = useState<UpdateUserAppsType | null>(null);
  const [genericPopup, setGenericPopup] = useState<GenericPopup | null>(null);

  return (
    <UserManagementContext.Provider
      value={{
        createUserBody,
        modifiedUserData,
        modifiedUserApps,
        genericPopup,
        setCreateUserBody,
        setModifiedUserData,
        setModifiedUserApps,
        setGenericPopup,
      }}
    >
      {children}

      {/* Show Generic Popup in case of success, error or information after an action */}
      {genericPopup && (
        <>
          <div className="backdrop access"></div>
          <div className="popup-container">
            <Popup type="msg" title={genericPopup.title} buttonAlignment="right" closePopup={() => setGenericPopup(null)} show={genericPopup !== null} hasCancelBtn={genericPopup.type === 'info' ? true : false} buttonLabel={genericPopup.btnLabel}>
              <GenericMessage
                type={genericPopup.type === 'success' ? 'success' : genericPopup.type === 'warning' ? 'warning' : 'error'}
                title={genericPopup.subtitle ? genericPopup.subtitle : genericPopup.type === 'success' ? t('success.success_title') : t('error.error_title')}
                msg={genericPopup.message}
              />
            </Popup>
          </div>
        </>
      )}
    </UserManagementContext.Provider>
  );
}

// Custom hook to use the context
export function useUserManagement() {
  const context = useContext(UserManagementContext);
  if (!context) {
    throw new Error('useUserManagement must be used within a CreateUserProvider');
  }
  return context;
}
