import { useTranslation } from 'react-i18next';
import './Footer.scss';
import Logo from '../../assets/images/footer/bics-logo-footer.svg';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="bc-container">
        <div className="bc-cols">
          <div className="bc-col bc-is-12">
            <img src={Logo} alt={t('footer.logo_alt')} />
            <p>{t('footer.text')}</p>
            <ul className="footer-links">
              <li>
                <a href={t('footer.links.legal.link')} title={t('footer.links.legal.link_title')} target="_blank">
                  {t('footer.links.legal.label')}
                </a>
              </li>
              <li>
                <a href={t('footer.links.privacy.link')} title={t('footer.links.privacy.link_title')} target="_blank">
                  {t('footer.links.privacy.label')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
