import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useKeycloak } from '../context/KeycloakContext';
import useAuthToken from './useAuthToken';

const useGetApi = <T = any>(url: string, params: object = {}, needsAuth: boolean = true, shouldFetch: boolean = true, repeatOnError: boolean = false) => {
  const { accessToken, accountId, handleSigninRedirect } = useKeycloak();
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [accessForbidden, setAccessForbidden] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = useAuthToken();
  let repeatCounter = 0;

  const fetchData = async (abortController: AbortController) => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<T> = await axios.get(url, {
        params: params,
        headers: !needsAuth ? {} : { Authorization: `Bearer ${token}` },
        signal: abortController.signal,
      });
      if (response.data) {
        setData(response.data);
      } else {
        throw new Error('Response data is undefined');
      }
      setError(null);
      setIsLoading(false);
    } catch (error: any) {
      const originalRequest = error.config;
      const message = error.response?.data || '';
      if (error.response?.status === 401 && message.toLowerCase().includes('token') && !originalRequest._retry) {
        handleSigninRedirect();
      }
      if (error.code !== 'ERR_CANCELED') {
        if (repeatOnError) {
          if (repeatCounter < 5) {
            fetchData(abortController);
            repeatCounter++;
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
        setError(error);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (!accessToken || !shouldFetch) return;

    fetchData(abortController);

    return () => {
      abortController.abort();
    };
  }, [url, accessToken, accountId, shouldFetch]);

  useEffect(() => {
    const errorStatus = error?.response?.status;
    if (!errorStatus) return;
    setAccessForbidden(errorStatus === 403 ? true : false);
  }, [error]);

  return {
    data,
    error,
    isLoading,
    accessForbidden,
    setData,
    setError,
    setIsLoading,
  };
};

export default useGetApi;
