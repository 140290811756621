import { Link } from 'react-router-dom';

type PrimaryButtonProps = {
  type: 'button' | 'internal' | 'external';
  label: string;
  link?: string;
  disabled?: boolean;
  handleAction?: () => void;
};

function PrimaryButton({ type, label, link, disabled, handleAction }: PrimaryButtonProps) {
  return (
    <>
      {type === 'button' ? (
        <button type="button" className="bc-btn--primary" onClick={handleAction} disabled={disabled ?? false}>
          {label}
        </button>
      ) : type === 'external' ? (
        <a href={link} title={label} rel="noreferrer" target="_blank" className="bc-btn--primary" onClick={handleAction}>
          {label}
        </a>
      ) : (
        <Link to={link!!} title={label} className="bc-btn--primary" onClick={handleAction}>
          {label}
        </Link>
      )}
    </>
  );
}

export default PrimaryButton;
