import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/_utilities.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { oidcConfig } from './utils/oidcConfig';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AuthProvider {...(oidcConfig as AuthProviderProps)}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthProvider>,
);

reportWebVitals();
