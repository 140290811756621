import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export type FrequentlyVisitedLink = {
  name: string;
  link: string;
  clicks: number;
};

export type FrequentlyVisitedContextType = {
  visitedLink: FrequentlyVisitedLink[];
  hasLinks: boolean;
  setVisitedLink: React.Dispatch<React.SetStateAction<FrequentlyVisitedLink[] | null>>;
  updateVisitedLink: (title: string, url: string) => void;
  activeApplication: ApplicationPage | null;
  setActiveApplication: React.Dispatch<React.SetStateAction<ApplicationPage | null>>;
};

export type ApplicationPage = {
  name: string;
  url: string;
};

const FrequentlyVisitedContext = createContext<FrequentlyVisitedContextType | undefined>(undefined);

export function FrequentlyVisitedProvider({ children }: { children: ReactNode }) {
  const [visitedLink, setVisitedLink] = useState<FrequentlyVisitedLink[] | null>(null);
  const { data, readStorage } = useLocalStorage<FrequentlyVisitedLink[]>('visitedLinks', visitedLink);
  const [hasLinks, setHasLinks] = useState<boolean>(false);
  const [activeApplication, setActiveApplication] = useState<ApplicationPage | null>(null);

  const updateVisitedLink = (linkName: string, url: string) => {
    if (!url || url === '/') return;
    setVisitedLink(() => {
      let updatedVisitedLink: FrequentlyVisitedLink[] = readStorage() || []; // Read from LS
      const existingLinkIndex = updatedVisitedLink.findIndex((item) => item.link === url);

      if (existingLinkIndex !== -1) {
        // If the object exists, update its clicks property to 1
        updatedVisitedLink[existingLinkIndex] = {
          ...updatedVisitedLink[existingLinkIndex],
          clicks: updatedVisitedLink[existingLinkIndex].clicks + 1,
        };
      } else {
        // If the object doesn't exist, add it to the array
        updatedVisitedLink.push({
          name: linkName,
          link: url,
          clicks: 1,
        });
      }

      updatedVisitedLink = updatedVisitedLink.sort((a: any, b: any) => b.clicks - a.clicks);
      return updatedVisitedLink;
    });
    if (hasLinks === false) {
      setHasLinks(true);
    }
  };

  useEffect(() => {
    if (hasLinks) return;
    if (data?.find((link) => link.clicks > 0)) {
      setHasLinks(true);
    }
  }, [data]);

  return (
    <FrequentlyVisitedContext.Provider
      value={{
        visitedLink: data ? data : [],
        hasLinks,
        activeApplication,
        setVisitedLink,
        updateVisitedLink,
        setActiveApplication,
      }}
    >
      {children}
    </FrequentlyVisitedContext.Provider>
  );
}

export function useFrequentlyVisited() {
  const context = useContext(FrequentlyVisitedContext);
  if (!context) {
    throw new Error('useFrequentlyVisited must be used within a FrequentlyVisitedProvider');
  }
  return context;
}
