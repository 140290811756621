import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import Spinner from '../Spinner/Spinner';
import './Popup.scss';

export type PopupProps = {
  buttonAlignment?: 'announcement' | 'left' | 'right';
  buttonLabel: string;
  secondaryBtnLabel?: string;
  children: React.ReactNode;
  hasCancelBtn: boolean;
  hasError?: boolean;
  id?: string;
  isLoading?: boolean;
  show: boolean;
  subtitle?: string;
  title?: string;
  type?: string;
  isBtnDisabled?: boolean;
  isSecondaryBtnDisabled?: boolean;
  callAction?: () => void;
  secondaryBtnAction?: () => void;
  closePopup: (id?: string) => void;
  externalHideAndClosePopup?: () => void;
};

function Popup({
  buttonAlignment,
  buttonLabel,
  children,
  hasCancelBtn,
  hasError,
  id,
  isLoading,
  show,
  subtitle,
  title,
  type,
  isBtnDisabled,
  isSecondaryBtnDisabled,
  secondaryBtnAction,
  closePopup,
  externalHideAndClosePopup,
  callAction,
  secondaryBtnLabel,
}: PopupProps) {
  const { t } = useTranslation();
  const [removeFromDom, setRemoveFromDom] = useState(false);
  const [isVisible, setIsVisible] = useState(show);

  const containerClasses = `
    btns-container
    btn-container
    bc-flex
    bc-items-center
    ${buttonAlignment === 'left' ? 'bc-justify-start no-border' : 'bc-justify-end'}
    ${!hasCancelBtn && buttonLabel === '' ? 'hide' : ''}
  `;

  const hideAndClosePopup = () => {
    if (externalHideAndClosePopup) {
      externalHideAndClosePopup();
    } else {
      if (id !== '' && id !== 'edit_user') {
        setIsVisible(false);
      }

      setTimeout(() => {
        id ? closePopup(id) : closePopup();
        id !== '' && id !== 'edit_user' && setRemoveFromDom(true);
      }, 250);
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  let secondaryBtnLabelValue = secondaryBtnLabel;
  if (!secondaryBtnLabelValue) secondaryBtnLabelValue = type === 'reject-access' ? t('pages.profile.access_request_overview.popup.btns.no') : t('general.cancel_btn');

  if (removeFromDom) return null;

  return (
    <div className={`popup ${!isVisible ? 'hidden' : ''} ${type ?? ''}`} onClick={(e) => e.stopPropagation()}>
      <div className="popup-header">
        {title && <h3>{title}</h3>}
        {subtitle && <h4>{subtitle}</h4>}
        <button type="button" className="bc-btn--tertiary bc-btn--icon closePopup" onClick={hideAndClosePopup}>
          <span className="bc-icon-cross"></span>
        </button>
      </div>

      {children}
      {!hasError && buttonAlignment ? (
        <div className={containerClasses}>
          {buttonAlignment === 'announcement' && <p className="detail">{t('pages.notifications.announcement_info')}</p>}
          {hasCancelBtn && (
            <div className="bc-mr-4">
              <SecondaryButton type="button" disabled={isSecondaryBtnDisabled || isLoading} label={secondaryBtnLabelValue!} handleAction={secondaryBtnAction ?? hideAndClosePopup} />
            </div>
          )}
          {buttonLabel !== '' && <PrimaryButton type="button" disabled={isBtnDisabled || isLoading} label={buttonLabel} handleAction={type === 'msg' || type === 'announcement' ? hideAndClosePopup : callAction} />}
        </div>
      ) : null}
      {isLoading && (
        <div className="loading-overlay">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Popup;
