import './BackToTop.scss';
import ArrowTop from '../../assets/images/footer/arrow-top.svg';
import { useEffect, useState } from 'react';

function BackToTop() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 600 ? setIsVisible(true) : setIsVisible(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const BackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`back-to-top ${isVisible ? 'show' : ''}`} onClick={BackToTop}>
      <img src={ArrowTop} alt="" />
    </div>
  );
}

export default BackToTop;
