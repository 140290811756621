// Used to handle Error messages, Empty messages, etc...
import ErrorIcon from '../../assets/images/genericmessage/error.svg';
import InfoIcon from '../../assets/images/genericmessage/info.svg';
import SuccessIcon from '../../assets/images/genericmessage/success.svg';
import Warning from '../../assets/images/genericmessage/warning.svg';
import GreenStar from '../../assets/images/general/green-star.svg';

import './GenericMessage.scss';

export type GenericMsgProps = {
  type: 'error' | 'info' | 'success' | 'warning' | 'empty' | 'empty-notifs';
  title?: string;
  msg: string;
};

function GenericMessage({ type, title, msg }: GenericMsgProps) {
  return (
    <div className={`generic-message ${type}`}>
      <img src={type === 'error' ? ErrorIcon : type === 'success' ? SuccessIcon : type === 'warning' ? Warning : type === 'empty-notifs' ? GreenStar : InfoIcon} alt="" />

      <div>
        <h5>{title}</h5>
        <h6>{msg}</h6>
      </div>
    </div>
  );
}

export default GenericMessage;
