import '../header/Header.scss';

function SkeletonHeader() {
  return (
    <header className="bc-header">
      <div className="bc-header__top"></div>
      <div className="bc-header__bottom" style={{ height: '4.5rem' }}></div>
    </header>
  );
}

export default SkeletonHeader;
