import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import useGetApi from '../hooks/useGetApi';
import { useKeycloak } from './KeycloakContext';

type Contact = {
  contactCategory: string;
  email: string;
  firstName: string;
  lastName: string;
  productsList: string[] | null;
  productType: string;
  id: string | null;
  role: string;
  rolesList: string[];
};

interface ApplicationNode {
  '@id': string;
  '@name': string;
  '@nodeType': string;
  '@nodes': [];
  '@path': string;
  active: boolean;
  appIconLink: string;
  category: string;
  name: string;
  newTab: boolean;
  url: string;
}

export type ContactGroup = {
  [key: string]: Contact[];
};

export type categoryApplicationGroup = {
  [key: string]: string;
};

type ContactsContextType = {
  contacts: ContactGroup | null;
  mainAccountContacts: ContactGroup | null;
  contactsTitle: string;
  isLoading: boolean;
  error: any;
  accessForbidden: boolean;
  setContactsTitle: React.Dispatch<React.SetStateAction<string>>;
  categoryApplicationUrls: categoryApplicationGroup;
};

const ContactsContext = createContext<ContactsContextType | undefined>(undefined);

export function ContactsProvider({ children }: { children: ReactNode }) {
  const [contactsTitle, setContactsTitle] = useState<string>('');
  const [categoryApplicationUrls, setCategoryApplicationUrls] = useState<categoryApplicationGroup>({});
  const { userAccounts, accountId } = useKeycloak();
  const [contacts, setContacts] = useState<ContactGroup | null>(null);
  const [mainAccountContacts, setMainAccountContacts] = useState<ContactGroup | null>(null);
  const { data, isLoading, error, accessForbidden } = useGetApi<ContactGroup>(`${process.env.REACT_APP_CONTACT_US}`, { rdbId: accountId, pageType: 'widget' });
  const { data: applicationsData } = useGetApi<ApplicationNode[]>(`${process.env.REACT_APP_MAGNOLIA_BICS_APPLICATIONS}`, {}, false);

  useEffect(() => {
    if (!data) return;

    const reorganizedContacts = {
      'Service Manager': data['Service Manager'],
      'Service Delivery Manager': data['Service Delivery Manager'],
      'Sales Manager': data['Sales Manager'],
    };

    const currentAccount = userAccounts.find((acc) => Number(acc.id) === accountId);
    if (currentAccount?.isMainAccount) setMainAccountContacts(reorganizedContacts);
    setContacts(reorganizedContacts);
  }, [data]);

  useEffect(() => {
    if (!Array.isArray(applicationsData)) return;
    const categories: categoryApplicationGroup = applicationsData.reduce((obj, app) => {
      if (app.category) obj[app.category] = app.newTab ? app.url : `/application/${app.name.replaceAll(' ', '-')}`;
      return obj;
    }, {} as categoryApplicationGroup);
    setCategoryApplicationUrls(categories);
  }, [applicationsData]);

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        mainAccountContacts,
        contactsTitle,
        isLoading,
        error,
        accessForbidden,
        setContactsTitle,
        categoryApplicationUrls,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}

export function useContacts() {
  const context = useContext(ContactsContext);
  if (!context) {
    throw new Error('useContacts must be used within a ContactsProvider');
  }
  return context;
}
